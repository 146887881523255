import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class FlashMessageController extends Controller {
  static values = {
    delay: Number,
    hidden: { type: Boolean, default: false },
  }

  connect() {
    useTransition(this)

    // Add event listeners for mouse over and mouse out
    this.element.addEventListener("mouseover", this.pauseHide.bind(this))
    this.element.addEventListener("mouseout", this.resumeHide.bind(this))

    if (this.hiddenValue === false) this.show();
  }

  disconnect() {
    this.hide()
  }

  show() {
    this.enter()

    if (this.hasDelayValue) {
      this.setHideTimeout()
    }
  }

  async hide() {
    if (this.timeout) clearTimeout(this.timeout);

    await this.leave()

    this.element.remove()
  }

  // Method to set a timeout to hide the element
  setHideTimeout() {
    this.timeout = setTimeout(this.hide.bind(this), this.delayValue)
  }

  // Method to pause the hide timeout when the user hovers over the element
  pauseHide() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  // Method to resume the hide timeout when the user stops hovering
  resumeHide() {
    if (!this.timeout && this.hasDelayValue) {
      this.setHideTimeout()
    }
  }
}
